import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import {
  ContractGeneral,
  ContractList,
  Login,
  Logout,
  MeterDetails,
  ResetPassword,
  Signup,
  RecoverPassword,
} from "./views";

import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { PrivateRoute, RouteWithLayout } from "./components";
import InternalError from "./views/InternalError";
import Settings from "./settings.yml";
import CRMDetail from "./views/CRM/Detail/CRMDetail";
import CRMList from "./views/CRM/List/CRMList";
import CRMCreate from "./views/CRM/Create/CRMCreate";
import Home from "./views/Home/Home";
import AttachmentsList from "./views/CRM/Detail/components/AttachmentsList";
import CRMPendingCasesList from "./views/CRM/PendingCases/CRMPendingCasesList";
import CRMPendingCasesDetail from "./views/CRM/PendingCases/CRMPendingCasesDetail";
import PendingCasesAttachmentList from "./views/CRM/PendingCases/components/PendingCasesAttachmentList";

const Routes = () => {
  const rootRedirection = (location) => {
    return (
      <Redirect
        from="/"
        to={{
          pathname: "/inicio",
          search: location.search,
          state: {from: location}
        }}
      />
    )
  }

  const CRM_Routes = () => {
    return Settings.crm_enabled ? (
      [
        <Route
          exact
          path="/solicitudes"
          render={({ location }) => rootRedirection(location)}
          key="1"
        />,
        <PrivateRoute
          exact
          path="/solicitudes/:sectionId"
          component={CRMList}
          layout={MainLayout}
          key="2"
        />,
        <PrivateRoute
          exact
          path="/solicitudes/:sectionId/crear"
          component={CRMCreate}
          layout={MainLayout}
          key="3"
        />,
        <PrivateRoute
          exact
          path="/solicitudes/:sectionId/:caseId/"
          component={CRMDetail}
          layout={MainLayout}
          key="4"
        />,
        <PrivateRoute
          exact
          path="/solicitudes/:sectionId/:caseId/attachments"
          component={AttachmentsList}
          layout={MainLayout}
          key="5"
        />,
        <PrivateRoute
          exact
          path="/pendientes"
          component={CRMPendingCasesList}
          layout={MainLayout}
          key="6"
        />,
        <PrivateRoute
          exact
          path="/pendientes/:caseId"
          component={CRMPendingCasesDetail}
          layout={MainLayout}
          key="7"
        />,
        <PrivateRoute
          exact
          path="/pendientes/:caseId/attachments"
          component={PendingCasesAttachmentList}
          layout={MainLayout}
          key="8"
        />,
      ]
    ) : null ;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path="/"
          render={({ location }) => rootRedirection(location)}
        />
        <RouteWithLayout
          exact
          path="/acceso"
          component={Login}
          layout={MinimalLayout}
        />
        <RouteWithLayout
          exact
          path="/activacion"
          component={Signup}
          layout={MinimalLayout}
        />
        <RouteWithLayout
          exact
          path="/logout"
          component={Logout}
          layout={React.Fragment}
        />
        <PrivateRoute
          exact
          path="/reset"
          component={ResetPassword}
          layout={MainLayout}
        />
        <RouteWithLayout
          exact
          path="/recuperar-clave"
          component={RecoverPassword}
          layout={MinimalLayout}
        />
        <PrivateRoute
          exact
          path="/contratos"
          component={ContractList}
          layout={MainLayout}
        />
        <PrivateRoute
          exact
          path="/contratos/:name"
          component={ContractGeneral}
          layout={MainLayout}
        />
        <PrivateRoute
          exact
          path="/contratos/:name/contador"
          component={MeterDetails}
          layout={MainLayout}
        />
        {CRM_Routes()}
        <PrivateRoute
          exact
          path="/inicio"
          component={Home}
          layout={MainLayout}
        />
        <PrivateRoute
          exact
          path="/internal-error"
          component={InternalError}
          layout={MinimalLayout}
        />
        <Redirect to="/not-found"/>
        <PrivateRoute
          exact
          path="/not-found"
          component={<React.Fragment/>}
          layout={MinimalLayout}
        />
      </Switch>
    </React.Fragment>
  )
}

export default Routes;